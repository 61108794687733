.quoteSection {
  padding: 81px 0;
  max-width: 964px !important;

  .quote {
    position: relative;
    font-size: 24px;
    text-align: center;
    line-height: 36px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      background: linear-gradient(
        90deg,
        rgba(163, 47, 47, 0) 0%,
        #ce1f26 50.5%,
        rgba(163, 47, 47, 0) 100%
      );
      width: 100%;
      height: 1px;
      left: 50%;
      transform: translateX(-50%);
    }

    p {
      span {
        font-family: Sarabun-Medium, serif;
      }
    }
  }
}

.darkTheme {
  .quote {
    p {
      color: #ffffffd9;

      & span:last-child {
        &:first-child {
          background-color: #ffffffd9;
          color: #191919;
        }
      }
    }
  }
}

.russianView {
  .quote {
    p {
      & span:last-child {
        &:first-child {
          font-family: 'Lato', Arial, serif;
        }
      }
    }
  }
}

@media (max-width: 960px) {
  .quoteSection {
    padding: 60px 0;

    .quote {
      padding: 0 15px;

      p {
        span {
          font-size: 20px;
        }
      }
    }
  }
}

.contentHandlerContainer {
  padding-top: 72px;

  .homePageWrapper {
    display: flex;
    padding-bottom: 25px;
    position: relative;

    .p,
    .span,
    .ul,
    .li {
      font-size: 20px !important;
    }

    .h1 {
      font-family: DMSerifDisplay-Regular, serif;
    }

    &::before {
      content: '';
      position: absolute;
      left: -60px;
      background: linear-gradient(180deg, #232836 0%, #6c7486 100%);
      height: 190px;
      width: 49px;
      top: 10px;
    }

    .homeContent {
      padding-left: 49px;
      max-width: 100%;
      width: 825px;
    }

    .title {
      font-size: 48px;
      color: #000;
      font-family: DMSerifDisplay-Regular, serif;
      width: 200px;
      max-width: 100%;
    }

    .ruTitle {
      font-family: 'Lato', Arial, serif;
    }
  }

  .p {
    font-size: 18px;
    margin: 0.625rem 0;
    line-height: 130%;
    font-family: Sarabun-Regular, serif;
  }

  .h1 {
    font-family: DMSerifDisplay-Regular, serif;
    font-weight: 400;
    color: #384052;
    text-align: center;
    font-size: 36px;
    margin: 0 0 30px;
  }

  &.darkTheme .h1 {
    color: #fff;
  }

  .a {
    color: #252626;
    font-weight: 700;
    text-decoration: none;
    font-family: Sarabun-Regular, serif;

    &:hover {
      text-decoration: underline;
    }
  }

  .span {
    font-size: 18px;
    margin-left: 1.5625rem;
    display: block;
    border-left: 0.0625rem solid #808080;
    padding-left: 1.5625rem;
    margin-bottom: 0.625rem;
    font-family: Sarabun-Regular, serif;
  }

  .email {
    text-align: right;

    & .a {
      font-weight: 300 !important;
    }
  }

  .ol,
  .ul {
    & .li {
      font-size: 18px;
      margin-bottom: 0.5rem;
      font-family: Sarabun-Regular, serif;
    }

    & .ul {
      margin-top: 0.5rem;
    }
  }

  // Image
  .imageLink img {
    max-width: 100%;
    max-height: calc(100vh - 2.5rem);
  }

  /* Main */
  .image {
    text-align: center;
    position: relative;

    & .imageActions {
      opacity: 0;
      transition: 0.3s;
      position: absolute;
      top: 0.625rem;
      left: 0.625rem;
      height: 1.875rem;
      background-color: #fff;
      box-shadow: 0rem 0rem 0.25rem -0.0625rem;
      border-radius: 0.3125rem;
      padding: 0.75rem;

      & .newWindow,
      & .zoom,
      & .pin {
        display: inline-block;
        width: 1.875rem;
        height: 1.875rem;
        margin-left: 0.625rem;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100%;
        cursor: pointer;
      }

      & .newWindow {
        background-image: url(/assets/new-window.png);
        background-size: 84%;
        background-position: right center;
      }

      & .zoom {
        background-image: url(/assets/zoom.png);
        margin-left: 0rem;
      }

      & .pin {
        background-image: url(/assets/pin.png);
      }

      & .unpin {
        background-image: url(/assets/unpin.png);
      }
    }

    &:hover .imageActions {
      opacity: 1;
    }

    & img {
      max-width: 100%;
      max-height: 18.75rem;
      cursor: pointer;
    }
  }
}

.ruVersion {
  .ol,
  .ul {
    & .li {
      font-family: 'Lato', Arial, serif;
    }
  }

  .span,
  .p,
  .a,
  .h1 {
    font-family: 'Lato', Arial, serif;
  }
}

.darkTheme {
  .a,
  .quote a {
    color: #dadada;
  }

  .homePageWrapper {
    &::before {
      background: linear-gradient(180deg, #485371 0%, #1e2129 100%);
    }

    .title {
      color: #fff;
    }
  }
}

@media (min-width: 851px) and (max-width: 1200px) {
  .contentHandlerContainer {
    .p {
      line-height: 1.375rem;
    }
  }
}

@media (max-width: 960px) {
  .contentHandlerContainer {
    padding-top: unset;

    .p {
      line-height: 1.5rem;
    }

    .image {
      & .imageActions {
        display: none !important;
      }
    }

    .homePageWrapper {
      flex-direction: column;
      padding-bottom: 63px;

      &::before {
        display: none;
      }
      
      .homeContent {
        padding-left: unset;
      }

      .title {
        width: fit-content;
        position: relative;
        margin-top: 0;
        font-size: 32px;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          background: linear-gradient(180deg, #232836 0%, #6c7486 100%);
          height: 16px;
          width: 273px;
          bottom: -15px;
        }
      }
    }
  }

  .darkTheme {
    .homePageWrapper {
      .title {
        &::after {
          background: linear-gradient(180deg, #485371 0%, #1e2129 100%);
        }
      }
    }
  }
}

.TrelloButton,
.DownloadButton {
  align-items: center;
  text-decoration: none;
  color: #28587b;
  font-size: 14px;

  &.darkTheme {
    color: #fff;
  }

  &:hover {
    text-decoration: underline;
  }

  & > img {
    margin-right: 6px;
  }
}

.DownloadButton {
  display: flex;
}

.TrelloButton {
  display: inline-flex;
  position: relative;
  top: 3px;

  & > img {
    width: 13px;
  }
}

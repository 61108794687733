.articleFooter {
  padding: 1rem 0.5rem;
  width: 100%;
  z-index: 1;
  background-color: #fafafa;
  line-height: 1rem;
  transition: 0.3s;
  box-sizing: border-box;

  & .footerSection {
    max-width: 61.5rem;
    text-align: center;
    margin: 0 auto;
    font-size: 0.75rem;

    color: #00000073;

    & .footerSectionLink {
      color: #00000073 !important;
      text-decoration: none;
    }
  }

  &.hidden {
    display: none;
  }

  &.darkTheme {
    background-color: #333333;

    .footerSection,
    .footerSectionLink {
      color: #ffffffd9;

      &.footerSectionLink {
        color: #ffffffd9 !important;
      }
    }
  }
}

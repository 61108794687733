.gradientBg {
  background-image: linear-gradient(
    221.26deg,
    rgba(173, 173, 173, 0) 15.82%,
    #cbcbcb 85.27%
  );
  width: 100%;
  height: 100%;
  max-width: 367px;
  height: 470px;
  padding: 1px;

  .wrapper {
    background: linear-gradient(231.08deg, #fcfcfc 0%, #f7f7f7 100%);
    width: 100%;
    height: 100%;
    max-width: 367px;
    height: 470px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .supporterInfo,
    .quoteWrapper {
      width: 100%;
      max-width: 336px;
    }

    p,
    span,
    h4 {
      font-size: 16px;
      font-weight: 500;
      font-family: Sarabun-Medium, sans-serif;
    }

    .quoteMark {
      margin-top: 24px;
      display: block;
      color: #ce1f26;
      font-size: 32px;
      font-family: Sarabun-Medium, sans-serif;
    }

    .quote {
      p {
        margin: 0 0 42px 0;
        line-height: 24px;
        height: 96px;
        max-height: 100%;
      }
    }

    .about {
      p {
        margin: 0 0 32px 0;
        font-size: 14px;
        line-height: 21px;
        font-family: Sarabun-Regular, sans-serif;
      }
    }

    .name {
      margin: 0 0 8px 0;
      font-family: Sarabun-Medium, sans-serif;
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
    }

    .image {
      border-radius: 50%;
      object-fit: cover;
      margin-bottom: 16px;
    }
  }
}

.darkTheme {
  background-image: linear-gradient(
    221.26deg,
    rgba(173, 173, 173, 0) 15.82%,
    rgba(203, 203, 203, 0.45) 85.27%
  );

  .wrapper {
    background: linear-gradient(222.62deg, #1b1e26 12.63%, #2e2e2e 100%);
  }

  .quote,
  .about {
    p {
      color: #fffffff5;
    }
  }

  .name {
    color: #ffffffd9;
  }
}

@media (max-width: 960px) {
  .gradientBg {
    max-width: 600px;
    width: -webkit-fill-available;
    width: -moz-available;

    .wrapper {
      max-width: 600px;
      width: -webkit-fill-available;
      width: -moz-available;

      .quoteWrapper {
        .quote {
          padding: 0 15px;
        }
      }
    }

    .about {
      padding: 0 15px;
    }
  }
}

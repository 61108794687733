.tool {
  text-decoration: none;
}

.wrapper {
  background: linear-gradient(
    240.44deg,
    rgba(163, 47, 47, 0) 38.32%,
    #ce1f26 89.21%
  );
  padding: 1px;
  width: 100%;
  height: 100%;
  max-width: 364px;

  .mainContentUrl {
    position: relative;
    z-index: 4;
  }

  .mainContent {
    width: 100%;
    height: 100%;
    max-width: 312px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 27px;
    background: linear-gradient(222.62deg, #ffffff 12.63%, #f7f7f7 100%);

    &:hover {
      background: linear-gradient(222.62deg, #fff 12.63%, #f0f0f0);
      cursor: pointer;
    }

    .iconWrapper {
      position: relative;

      .japaneseName {
        position: absolute;
        top: 21px;
        width: max-content;
        right: 50%;
        transform: translateX(50%);
        font-size: 36px;
        line-height: 30.5px;
        font-family: Lato, sans-serif;
        color: #444444;
        margin: 0;
        font-weight: 700;
      }
    }

    .name,
    p {
      color: #000000d9;
    }

    .name {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      margin: 16px 0;
      font-family: Sarabun-Regular, serif;
      text-align: center;
    }

    p {
      margin: 0;
      text-align: center;
      line-height: 24px;
      font-family: Sarabun-Regular, serif;

      span {
        font-family: Sarabun-Regular, serif;
      }
    }
  }
}

.darkTheme {
  .mainContent {
    background: linear-gradient(222.62deg, #1b1e26 12.63%, #2e2e2e 100%);

    &:hover {
      background: #1c1c1c;
    }

    .name,
    p {
      color: #ffffffd9;
    }

    .iconWrapper {
      .japaneseName {
        color: #909090;
      }
    }
  }
}

.small {
  height: 328px;

  .mainContent {
    height: 328px;
  }
}

.medium {
  height: 352px;

  .mainContent {
    height: 352px;
  }
}

.large {
  height: 376px;

  .mainContent {
    height: 376px;
  }
}

.russianView {
  .mainContent {
    .name,
    p {
      font-family: 'Lato', Arial, serif;
    }

    p {
    }
  }
}

@media (max-width: 1000px) {
  .wrapper {
    max-width: 600px;

    .mainContent {
      max-width: 600px;
      padding: 0 5px;

      .iconWrapper {
        .japaneseName {
          font-size: 25px;
        }
      }
    }
  }
  .small,
  .medium,
  .large {
    height: 376px;

    .mainContent {
      height: 376px;
    }
  }
}

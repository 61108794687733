@import '@styles/_animations.scss';

.headline {
  display: flex;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  height: 84vh;
  position: relative;
  overflow: hidden;

  .test {
    position: absolute;
    right: 32px;
    top: 20px;
    cursor: pointer;
    display: flex;
  }
  .serenityBtn {
    margin-left: 5px;
  }

  .headlineInfo {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1440px;
    height: auto;
    max-height: 100%;

    .videoContainer {
      .video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background-color: unset;
      }
    }

    .videoContainerMobile {
      display: none;
    }
  }

  .contentWrapper {
    width: 600px;
    padding-left: 150px;

    .headlineContent {
      > * {
        font-family: Sarabun-Regular, serif;
        color: #000000;
      }

      h1 {
        font-size: 21px;
        margin: 0 0 8px 0;
      }

      h1,
      p,
      a,
      span {
        font-family: Sarabun-Regular, serif;
      }

      p {
        font-size: 16px;
      }

      a {
        color: #000000;
      }
    }

    .defaultState {
      opacity: 0;
    }

    .fadeOut {
      opacity: 0;
      @extend .animate-fadeOut;
      pointer-events: none;
    }

    .fadeIn {
      @extend .animate-fadeInLeft;
    }
  }

  .canvas {
    width: 600px;
    margin-left: -11px;
    padding-top: 13px;
    padding-left: 0;
  }

  .socialMedia {
    display: flex;
    gap: 24px;
    padding-top: 5px;
    align-items: center;

    .btn {
      border-radius: 2px;
      width: 100px;
      height: 32px;
      font-size: 16px;
      margin-right: unset;
      background-color: unset;
      border: 1px solid #303030;
      color: #303030;
      transition:
        background 0.3s ease,
        color 0.3s ease,
        border 0.3s ease;

      &:hover {
        background: #303030;
        color: #ffffff;
        border: 1px solid #303030;
      }
    }
  }
  .canvasLogos {
    padding-left: 150px;
  }

  .img {
    width: 50%;
    max-width: 690px;
    height: auto;
  }

  .imgMobile {
    display: none;
  }
}

.darkTheme {
  .serenityBtn {
    color: #252626;
  }
  .contentWrapper {
    .headlineContent {
      h1,
      p,
      a,
      span {
        color: rgb(0, 0, 0);
      }
    }
  }

  .socialMedia {
    .btn {
      background-color: unset;
      color: rgb(0, 0, 0);
    }
  }
}

.russianView {
  .contentWrapper {
    .headlineContent {
      h1,
      p,
      a,
      span {
        font-family: 'Lato', Arial, serif;
      }
    }
  }
}

@media (min-width: 1921px) {
  .headline {
    height: 800px;

    .contentWrapper {
      padding-left: 0;
      padding-top: 0;
    }

    .headlineInfo {
      justify-content: center;
      align-items: center;

      .videoContainer {
        .video {
          height: 800px;
          object-fit: none;
          background-color: #e7e7e7;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .headline {
    height: 600px;
  }
}

@media (max-width: 1140px) {
  .headline {
    .contentWrapper {
      padding-left: 50px;
    }
  }
}

@media (max-width: 960px) {
  .headline {
    height: 100%;

    .leaf,
    .serenityBtn {
      display: none;
    }

    .headlineInfo {
      flex-direction: column-reverse;
      padding-bottom: 30px;
      align-items: unset;

      .videoContainer {
        display: none;

        .video {
          display: none;
        }
      }

      .videoContainerMobile {
        display: block;
        width: 786px;
        max-width: 100%;
        height: auto;
        position: relative;

        .video {
          width: 100vw;
          height: 55vh;
          -o-object-fit: cover;
          object-fit: cover;
          -o-object-position: left center;
          object-position: right;
        }
      }
    }

    .contentWrapper {
      padding-left: 15px;
      padding-top: unset;
      width: unset;

      .headlineContent {
        padding-top: 20px;
      }
    }

    .img {
      display: none;
    }
  }
  .darkTheme {
    .serenityBtn {
      display: none;
    }

    .contentWrapper {
      .headlineContent {
        h1,
        p,
        a,
        span {
          color: #ffffffd9;
        }
      }
    }

    .socialMedia {
      .btn {
        background-color: unset;
        color: #ffffffd9;
        border: 1px solid #ffffffd9;

        &:hover {
          background: #ffffffd9;
          color: #000000;
          border: 1px solid #ffffffd9;
        }
      }
    }
  }
}

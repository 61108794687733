.zoomBlock {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(128, 128, 128, 0.9);
  width: 100%;
  height: 100vh;
  text-align: center;
  padding: 1.25rem;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;

  & div {
    max-height: 100%;

    & img {
      max-width: 100%;
      max-height: calc(100vh - 2.5rem);
    }
  }
}

@media (max-width: 851px) {
  .zoomBlock {
    display: none !important;
  }
}

.tools {
  display: flex;
  padding: 64px 0;
  flex-direction: column;
  align-items: unset !important;

  .title {
    font-size: 24px;
    line-height: 36px;
    font-family: Sarabun-Medium, sans-serif;
    font-weight: 500;
    margin: 0 0 24px 0;
  }

  .toolsWrapper {
    display: flex;
    gap: 20px;
    align-items: center;
  }
}

.darkTheme {
  .title {
    color: #ffffffd9;
  }
}

@media (max-width: 1000px) {
  .tools {
    padding: 0 0 40px 0;

    .toolsWrapper {
      flex-direction: column;
      align-items: center;
    }
  }
}

@media (max-width: 960px) {
  .tools {
    .title {
      font-size: 20px;
    }
  }
}


.pageWrapper {
  background-image: url('/assets/home-layout.png');
  overflow-x: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.darkBg {
  background-color: #1b1f2c;
  background-image: url('/assets/home-layout-dark.png');
}

.main {
  max-width: 960px;
  margin: 0 auto;
  min-height: calc(100vh - 64px);

  & .article {
    font-weight: 300;
    padding-bottom: 5rem;

    &:fullscreen::backdrop {
      background: transparent;
    }

    &.fullscreen {
      padding: 3.75rem;
      overflow: auto;
      color: #5d6063;
    }

    & .section {
      max-width: 59.375rem;
      margin: 0 auto;
      &::-webkit-scrollbar {
        width: 7px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: rgba(40, 88, 123, 0.5);
        border-radius: 5px;
      }

      & > p:nth-child(2) {
        margin-top: 0;
      }
    }
  }
}


@media (max-width: 960px) {
  .main {
    display: block;
    width: 100%;

    & .article {
      width: 100%;
      padding: 100px 24px 5rem;
      box-sizing: border-box;
    }
    & .homePage {
      padding: 0 24px 5rem;
    }
  }
}

.main {
  & .article.darkTheme.fullscreen {
    color: #dadada;
  }
}

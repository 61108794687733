.usedBy {
  height: 251px;
  position: relative;
  z-index: 2;
  max-width: 1440px !important;
  display: flex;
  flex-direction: column;
  text-align: left;

  .title {
    font-size: 24px;
    line-height: 36px;
    font-family: Sarabun-Medium, sans-serif;
    font-weight: 500;
    margin: 0 0 30px 0;
    width: 100%;
    max-width: 1140px;
    text-align: left;
  }

  .slider {
    width: 100%;
    max-width: 1140px;
    z-index: 4;
    display: flex;

    div {
      display: flex;
      align-items: center;
      gap: 80px;

      div {
        width: unset !important;
        will-change: auto;
        &:focus-visible {
          outline: unset;
        }
      }
    }

    .usedByItem {
      .img {
        object-fit: contain;
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%;
    background-image: url('/assets/landingPage/usedByBg.png');
    opacity: 0.15;
    z-index: 1;
  }
}

.darkTheme {
  .title {
    color: #ffffffd9;
    z-index: 1;
  }

  &::before {
    background-image: url('/assets/landingPage/usedByDark.png');
    background-size: cover;
    opacity: 1;
  }
}

@media (max-width: 960px) {
  .usedBy {
    .title {
      padding-left: 30px;
      font-size: 20px;
    }

    .slider {
      div {
        gap: 40px;
      }

      .usedByItem {
        .img {
          object-fit: contain;
        }
      }
    }
  }
}

.supporters {
  display: flex;
  padding: 64px 0;
  flex-direction: column;
  align-items: baseline !important;

  .title {
    font-size: 24px;
    line-height: 36px;
    font-family: Sarabun-Medium, sans-serif;
    font-weight: 500;
    margin: 0 0 24px 0;
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    row-gap: 10px;
    column-gap: 15px;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.darkTheme {
  .title {
    color: #ffffffd9;
  }
}

@media (max-width: 1170px) {
  .supporters {
    .wrapper {
      justify-content: center;
    }
  }
}

@media (max-width: 960px) {
  .supporters {
    padding: 40px 0;

    .title {
      font-size: 20px;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.mainSections {
  background-color: #fff;

  section {
    display: flex;
    width: 100%;
    max-width: 1140px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
}

.darkTheme {
  background-color: #1b1e26;
}

.russianView {
  > p,
  span,
  a {
    font-family: 'Lato', Arial, serif;
  }
}

@media (max-width: 1200px) {
  .mainSections {
    section {
      margin: 0 15px;
      max-width: unset;
      width: unset;
      overflow: hidden;
    }

    section:first-child,
    section:nth-child(3) {
      margin: 0 auto;
      width: 100%;
      max-width: 1140px;
    }
  }
}

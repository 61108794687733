.projects {
  display: flex;
  flex-direction: column;
  padding: 64px 0 153px 0;

  .project {
    padding-bottom: 48px;
  }

  .redLine {
    width: 310px;
    background: linear-gradient(90deg, #ce1f26 0%, rgba(206, 31, 38, 0) 100%);
    height: 1px;
    margin: 0;
    border: none;
  }

  .name {
    font-size: 24px;
    margin: 10px 0;
    line-height: 36px;
    font-family: Sarabun-Medium, sans-serif;
    font-weight: 500;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    font-family: Sarabun-Regular, sans-serif;
    margin: 0;
  }
}

.darkTheme {
  .name,
  p {
    color: #ffffffd9;
  }
}

@media (max-width: 960px) {
  .projects {
    padding: 40px 0 100px 0;
  }
}

.PageNotFound {
  margin: 0;
  padding: 0;
  background: url(/assets/biases/bg.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-top: calc(50vh - 200px);
    margin-bottom: 44px;
  }

  .Title {
    font-size: 36px;
    margin-bottom: 18px;
    color: #9e9e9e;
  }

  .Description {
    margin-bottom: 30px;
    color: #333333;
  }
}
